import { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('********', error, errorInfo);
    const jsonParseError = error?.message?.includes('is not valid JSON');
    console.log('JSON.parse error occured');

    if (jsonParseError) {
      console.error('Clearing localstorage....');
      localStorage.clear();
    }
  }

  render() {
    return this.props.children;
  }
}
