import { ReactComponent as regional } from 'assets/icons/regional.svg';
import { ReactComponent as sectional } from 'assets/icons/sectional.svg';
import { ReactComponent as nabc } from 'assets/icons/nabc.svg';
import { ReactComponent as others } from 'assets/icons/others.svg';
import { ReactComponent as gnt } from 'assets/icons/gnt.svg';
import { ReactComponent as heart } from 'assets/icons/heart.svg';
import { ReactComponent as search } from 'assets/icons/search.svg';
import { ReactComponent as searchWhite } from 'assets/icons/search-white.svg';
import { ReactComponent as plus } from 'assets/icons/plus.svg';
import { ReactComponent as minus } from 'assets/icons/minus.svg';
import { ReactComponent as arrowBottom } from 'assets/icons/arrow-bottom.svg';
import { ReactComponent as arrowBottomActive } from 'assets/icons/arrow-bottom-active.svg';
import { ReactComponent as tourType } from 'assets/icons/tour-type.svg';
import { ReactComponent as phone } from 'assets/icons/phone.svg';
import { ReactComponent as location } from 'assets/icons/location.svg';
import { ReactComponent as loopWhite } from 'assets/icons/loop-white.svg';
import { ReactComponent as district } from 'assets/icons/district.svg';
import { ReactComponent as pair } from 'assets/icons/pair-new.svg';
import { ReactComponent as team } from 'assets/icons/team-new.svg';
import { ReactComponent as cross } from 'assets/icons/cross.svg';
import { ReactComponent as trash } from 'assets/icons/trash.svg';
import { ReactComponent as masterpoint } from 'assets/icons/masterpoint.svg';
import { ReactComponent as defis } from 'assets/icons/defis.svg';
import { ReactComponent as calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as person } from 'assets/icons/person.svg';
import { ReactComponent as registered } from 'assets/icons/registered.svg';
import { ReactComponent as download } from 'assets/icons/download.svg';
import { ReactComponent as check } from 'assets/icons/check.svg';
import { ReactComponent as checkGreen } from 'assets/icons/checkGreen.svg';
import { ReactComponent as info } from 'assets/icons/info.svg';
import { ReactComponent as infoRed } from 'assets/icons/infoRed.svg';
import { ReactComponent as filterby } from 'assets/icons/filterby.svg';
import { ReactComponent as loopGrey } from 'assets/icons/search-grey.svg';
import { ReactComponent as left } from 'assets/icons/left.svg';
import { ReactComponent as assignedSeats } from 'assets/icons/assigned_seats.svg';
import { ReactComponent as electricPlug } from 'assets/icons/electric_plug.svg';
import { ReactComponent as ticket } from 'assets/icons/ticket.svg';
import { ReactComponent as pairNew } from 'assets/icons/pair-new.svg';
import { ReactComponent as teamNew } from 'assets/icons/team-new.svg';
import { ReactComponent as gridViewIconWhite } from 'assets/icons/grid-view-white.svg';
import { ReactComponent as listViewIconBlue } from 'assets/icons/list-view-blue.svg';
import { ReactComponent as gridViewIconBlue } from 'assets/icons/grid-view-blue.svg';
import { ReactComponent as listViewIconWhite } from 'assets/icons/list-view-white.svg';
import { ReactComponent as canceledTicket } from 'assets/icons/canceled-ticket.svg';
import { ReactComponent as logoAmex } from 'assets/icons/credit_cards_logo_amex.svg';
import { ReactComponent as logoDiscover } from 'assets/icons/credit_cards_logo_discover.svg';
import { ReactComponent as masterCard } from 'assets/icons/mastercard.svg';
import { ReactComponent as visaCard } from 'assets/icons/visa.svg';
import { ReactComponent as menuDots } from 'assets/icons/menu-dots.svg';
import { ReactComponent as email } from 'assets/icons/email.svg';
import { ReactComponent as checkWhite } from 'assets/icons/checkWhite.svg';
import { ReactComponent as print } from 'assets/icons/print.svg';
import { ReactComponent as pdf } from 'assets/icons/pdf.svg';
import { ReactComponent as link } from 'assets/icons/link.svg';
import { ReactComponent as flyerDownload } from 'assets/icons/flyer-download.svg';
import { ReactComponent as earth } from 'assets/icons/earth.svg';
import { ReactComponent as hotel } from 'assets/icons/hotel.svg';
import { ReactComponent as headerLogo } from 'assets/icons/header-logo.svg';
import { ReactComponent as plusWhite } from 'assets/icons/plus-white.svg';
import { ReactComponent as minusWhite } from 'assets/icons/minus-white.svg';
import { ReactComponent as locationBlue } from 'assets/icons/location-blue.svg';
import { ReactComponent as filledArrow } from 'assets/icons/arrow-filled.svg';
import { ReactComponent as phoneBlue } from 'assets/icons/phoneBlue.svg';
import { ReactComponent as districtBlue } from 'assets/icons/district-blue.svg';
import { ReactComponent as personBlue } from 'assets/icons/person-blue.svg';

export const ICON_COMPONENTS = Object.freeze({
  trash,
  cross,
  calendar,
  pairNew,
  teamNew,
  filterby,
  loopGrey,
  email,
  defis,
  left,
  masterpoint,
  regional,
  team,
  minus,
  pair,
  tourType,
  location,
  district,
  loopWhite,
  phone,
  sectional,
  others,
  nabc,
  gnt,
  heart,
  search,
  plus,
  arrowBottom,
  arrowBottomActive,
  person,
  registered,
  download,
  check,
  checkGreen,
  info,
  infoRed,
  assignedSeats,
  electricPlug,
  ticket,
  searchWhite,
  gridViewIconWhite,
  listViewIconBlue,
  gridViewIconBlue,
  listViewIconWhite,
  canceledTicket,
  logoAmex,
  logoDiscover,
  masterCard,
  visaCard,
  menuDots,
  checkWhite,
  print,
  pdf,
  link,
  flyerDownload,
  earth,
  hotel,
  headerLogo,
  plusWhite,
  minusWhite,
  locationBlue,
  filledArrow,
  phoneBlue,
  districtBlue,
  personBlue,
});
